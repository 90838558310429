import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const AnimateIntroBlock = makeShortcode("AnimateIntroBlock");
const Decoration = makeShortcode("Decoration");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo dark" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Solutions
    </Heading>
    <LearnMore elementId="learn_more_target" color="turq" mdxType="LearnMore" />
  </Block>
  
  <Block className="content_block" mdxType="Block">
    <Paragraph mdxType="Paragraph">Our integrated solutions make sure your business runs optimally, with a meticulous approach to security, safety, upkeep, and business continuity.</Paragraph>
  </Block>  
    </AnimateIntroBanner>
    <Row id="learn_more_target" className="content" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Energy Management,<br /> Budgeting & Cost Control </Heading>
  <div>
  <Paragraph mdxType="Paragraph">Our approach to budget planning and execution focuses on adding value and providing cost control solutions – for higher revenue, lower costs, and more accurate purchasing decisions. </Paragraph>
  <Paragraph mdxType="Paragraph">This can range from Life Cycle Costing Analysis of your equipment and facility to Capital Expenditure (CAPEX) and Operating Expenses (OPEX), which our team can develop and monitor. We can also prepare an energy management plan to help you control, reduce, and save on energy consumption.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/solutions/energy-management.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Environment,<br /> Health & Safety</Heading>
  <div>
  <Paragraph mdxType="Paragraph">Remaining steadfast to the health and safety of all facility occupants and staff, we formulate a complete set of Environment, Health and Safety procedures in compliance with internationally recognized standards. </Paragraph>
  <Paragraph mdxType="Paragraph">This includes fire drills, Occupational Health and Safety manuals, Job Hazard Analysis, and technical emergency plans. We can also provide First Aid and Clinic Services, Kitchen and Food Safety, as well as Indoor Air Quality Audits to ensure optimal air and environment quality.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/solutions/health-safety-environment.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock> 
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Managing Agent<br /> & Consultancy </Heading>
  <div>
  <Paragraph mdxType="Paragraph">Because every project requires a different approach, our services can be handpicked or mixed and matched to cater to what you truly need for your business, from full-fledged integrated facility management to consultancy. Our team of experts can also act as managing agents to manage and oversee the project on your behalf.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/solutions/managing-agent.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>    
  
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Risk Management </Heading>
  <div>
  <Paragraph mdxType="Paragraph">We make it a point to be proactive in identifying and preventing potential drawbacks to keep your business running smoothly, with a keen sense of safety, schedule, and cost-effectiveness. </Paragraph>
  <Paragraph mdxType="Paragraph">Through Risk and Technical Engineering Support and Preparedness Plans, our multi-layered approach to risk assessment is focused on business continuity at all times.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/solutions/risk-management.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>  
  
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Smart Solutions<br /> & Technologies </Heading>
  <div>
  <Paragraph mdxType="Paragraph">At Hausman, we strive to stay on top of the latest smart solutions and technologies in the Facility Management and Operations & Maintenance industry. </Paragraph>
  <Paragraph mdxType="Paragraph">Equipped with the latest Computer-aided facility management (CAFM) software, BIM technology, drones for inspections, chatbot applications for facility occupants, and various IoT solutions, the Hausman team can effectively plan, monitor, and manage countless facility management activities through seamless processes and workflows.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/solutions/smart-solutions.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>    
    </Row>
    <Row className="whitespace" background={<RowLines mdxType="RowLines" />} mdxType="Row"></Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      